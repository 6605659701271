import './Skills.css';
import { useState } from 'react';
import { OrbitControls, Text } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { Physics } from '@react-three/cannon';
import skillBlocks from '../objects/skillBlocks.json';
import Floor from 'objects/Floor';
import SkillBlock from 'objects/SkillBlock';

const Skills = () => {
  const [skill, setSkill] = useState('Skills');

  const updateSkillText = (skill: string) => {
    setSkill(skill.split('_')[2]);
  };

  return (
    <div id="skills">
      <Canvas shadows camera={{ fov: 70, position: [0, 1, 4] }}>
        <OrbitControls
          autoRotate
          autoRotateSpeed={0.1}
          enablePan={false}
          enableZoom={false}
          minPolarAngle={1}
          maxPolarAngle={1.6}
        />
        <color attach="background" args={['black']} />
        <hemisphereLight intensity={0.35} />
        <spotLight
          position={[5, 5, 5]}
          angle={0.3}
          penumbra={0.8}
          intensity={2}
          castShadow
          shadow-mapSize-width={256}
          shadow-mapSize-height={256}
        />
        <Text
          fillOpacity={0.1}
          color={'#555555'}
          fontSize={2.2}
          position={[0, -0.795, 0]}
          rotation={[-Math.PI / 2, 0, 0]}
        >
          {skill}
        </Text>
        <Physics>
          <Floor rotation={[-Math.PI / 2, 0, 0]} position={[0, -0.8, 0]} />

          {skillBlocks.filenames.map((_x, id) => (
            <group key={id} onPointerEnter={() => updateSkillText(skillBlocks.filenames[id])}>
              <SkillBlock key={id} blockId={id} />
            </group>
          ))}
        </Physics>
      </Canvas>
    </div>
  );
};

export default Skills;
