import { useRef, useState } from 'react';
import { useBox } from '@react-three/cannon';
import { useGLTF } from '@react-three/drei';
import { GLTF } from 'three-stdlib';
import { Mesh } from 'three';
import skillBlocks from './skillBlocks.json';

type Props = {
  blockId: number;
};

type GLTFResult = GLTF & {
  nodes: {
    SkillsCube: THREE.Mesh;
  };
  materials: {
    Material: THREE.MeshStandardMaterial;
  };
};

const SkillBlock = ({ blockId }: Props) => {
  const path = `objects/${skillBlocks.filenames[blockId]}.glb`;

  const [ref] = useBox(
    () => ({
      mass: 0.8,
      args: [1.2, 1.2, 1.2],
      position: [
        Math.floor(Math.random() * 15) - 9,
        Math.floor(Math.random() * 30) + 10,
        Math.floor(Math.random() * 9) - 9,
      ],
    }),
    useRef<Mesh>(null),
  );

  const { nodes, materials } = useGLTF(path) as unknown as GLTFResult;

  return <mesh ref={ref} castShadow geometry={nodes.SkillsCube.geometry} material={materials.Material} />;
};

export default SkillBlock;
