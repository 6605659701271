import Menu from './components/Menu';
import Contact from './pages/Contact';
import Footer from './pages/Footer';
import Home from './pages/Home';
import Projects from './pages/Projects';
import Design from 'pages/Design';
import Skills from './pages/Skills';

function App() {
  return (
    <div>
      <Menu />
      <Home />
      <Skills />
      <Projects />
      <Design />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
