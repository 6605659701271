import './Design.css';
import img01 from '../assets/designs/wedd-forget-me-not.jpg';
import img02 from '../assets/designs/inv-board-game-night.jpg';
import img03 from '../assets/designs/grad-neon-glow-colors.jpg';
import img04 from '../assets/designs/wedd-sunflowers-menu-inv.jpg';
import img05 from '../assets/designs/bach-coastal-cowgirl-inv.jpg';
import img06 from '../assets/designs/office-aesthetician-businesscard.jpg';
import img07 from '../assets/designs/grad-bold-pink-orange-ann.jpg';
import img08 from '../assets/designs/wedd-neon-floral-inv.jpg';
import img09 from '../assets/designs/nye-fireworks-inv.jpg';

const Design = () => {
  return (
    <div className="designsContainer" id="designs">
      <div className="designs">
        <h1>Recent Designs</h1>
      </div>
      <div className="designItems">
        <div className="designItem">
          <img src={img01} className="designImage" alt="Wedding Invitation" />
          <h2>Wedding Invitation</h2>
        </div>
        <div className="designItem">
          <img src={img02} className="designImage" alt="Game Night Invitation" />
          <h2>Game Night Invitation</h2>
        </div>
        <div className="designItem">
          <img src={img03} className="designImage" alt="Graduation Collection" />
          <h2>Graduation Collection</h2>
        </div>
        <div className="designItem">
          <img src={img04} className="designImage" alt="Wedding Collection" />
          <h2>Wedding Collection</h2>
        </div>
        <div className="designItem">
          <img src={img05} className="designImage" alt="Bachelorette Invitation" />
          <h2>Bachelorette Invitation (using AI)</h2>
        </div>
        <div className="designItem">
          <img src={img06} className="designImage" alt="Business Card" />
          <h2>Business Card</h2>
        </div>
        <div className="designItem">
          <img src={img07} className="designImage" alt="Graduation Invitation" />
          <h2>Graduation Invitation</h2>
        </div>
        <div className="designItem">
          <img src={img08} className="designImage" alt="Wedding Invitation" />
          <h2>Wedding Invitation</h2>
        </div>
        <div className="designItem">
          <img src={img09} className="designImage" alt="New Year's Eve Invitation" />
          <h2>New Year's Eve Invitation</h2>
        </div>
      </div>
      <div className="designsInfo">
        The designs shown are made for the Print on Demand business such as wedding and birthday invitations. They're
        created using Inkscape in vector graphics and Photoshop for the in situ image, some are using stock assets or
        AI.
      </div>
    </div>
  );
};

export default Design;
