import { PlaneProps, usePlane } from '@react-three/cannon';
import { useRef } from 'react';
import { Mesh } from 'three';

const Floor = (props: PlaneProps) => {
  const [ref] = usePlane(() => ({ ...props }), useRef<Mesh>(null));

  return (
    <mesh ref={ref} receiveShadow>
      <planeGeometry args={[150, 150]} />
      <meshStandardMaterial color={'#CCCCCC'} />
    </mesh>
  );
};

export default Floor;
