import './Contact.css';
import imgTwitter from '../assets/twitter-icon-blue.png';

const Contact = () => {
  return (
    <div className="contactContainer" id="contact">
      <div>
        <h1>Contact</h1>
      </div>
      <div className="contact">
        <div className="contactItem tagLine">Get in touch.</div>
        <div className="contactItem">
          ✉️ <a href="mailto:info@nxdec.io">info@nxdec.io</a> - <img src={imgTwitter} alt="twitter" className="bird" />{' '}
          <a href="https://twitter.com/nxtweetsocial">NXTweets</a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
