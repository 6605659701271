import './Menu.css';

const Menu = () => {
  return (
    <div className="menu">
      <div className="menuItem">
        <a href="#home">Home</a>
      </div>
      <div className="menuItem">
        <a href="#skills">Skills</a>
      </div>
      <div className="menuItem">
        <a href="#projects">Projects</a>
      </div>
      <div className="menuItem">
        <a href="#designs">Designs</a>
      </div>
      <div className="menuItem">
        <a href="#contact">Contact</a>
      </div>
    </div>
  );
};

export default Menu;
