import './Home.css';
import imgNXDEC from '../assets/nxdeclogo.jpg';

const Home = () => {
  return (
    <div className="home" id="home">
      <div className="homeItem">
        <img src={imgNXDEC} alt="NXDEC" className="nxdecLogo" />
      </div>
      <div className="homeItem tagLine">Software development and design for web, VR and print.</div>
    </div>
  );
};

export default Home;
